// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["l7UUMRneJ", "y3kdTaXk5"];

const serializationHash = "framer-6IHNN"

const variantClassNames = {l7UUMRneJ: "framer-v-4fugl0", y3kdTaXk5: "framer-v-1s8erf0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {close: "y3kdTaXk5", menu: "l7UUMRneJ"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, Sqyl6Ei8Z: tap ?? props.Sqyl6Ei8Z, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "l7UUMRneJ"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Sqyl6Ei8Z, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "l7UUMRneJ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1k5rwa5 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (Sqyl6Ei8Z) {
const res = await Sqyl6Ei8Z(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "y3kdTaXk5") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-4fugl0", className, classNames)} data-framer-name={"menu"} data-highlight layoutDependency={layoutDependency} layoutId={"l7UUMRneJ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1k5rwa5} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(246, 242, 236)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} {...addPropertyOverrides({y3kdTaXk5: {"data-framer-name": "close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1svay8v"} layoutDependency={layoutDependency} layoutId={"CokxfALDJ"} style={{backgroundColor: "var(--token-3d72c0b7-371d-49a5-a181-bc6c69b2236e, rgb(0, 63, 79))", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, rotate: 0}} variants={{y3kdTaXk5: {rotate: 45}}}/>{isDisplayed() && (<motion.div className={"framer-1cztm09"} layoutDependency={layoutDependency} layoutId={"RkwyAhzIZ"} style={{backgroundColor: "var(--token-3d72c0b7-371d-49a5-a181-bc6c69b2236e, rgb(0, 63, 79))", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50}}/>)}<motion.div className={"framer-mkx79k"} layoutDependency={layoutDependency} layoutId={"NlU8i76Vg"} style={{backgroundColor: "var(--token-3d72c0b7-371d-49a5-a181-bc6c69b2236e, rgb(0, 63, 79))", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, rotate: 0}} variants={{y3kdTaXk5: {rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6IHNN.framer-1awishp, .framer-6IHNN .framer-1awishp { display: block; }", ".framer-6IHNN.framer-4fugl0 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 6px; height: 45px; justify-content: center; padding: 0px; position: relative; width: 45px; }", ".framer-6IHNN .framer-1svay8v, .framer-6IHNN .framer-1cztm09, .framer-6IHNN .framer-mkx79k { flex: none; height: 2px; overflow: hidden; position: relative; width: 21px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6IHNN.framer-4fugl0 { gap: 0px; } .framer-6IHNN.framer-4fugl0 > * { margin: 0px; margin-bottom: calc(6px / 2); margin-top: calc(6px / 2); } .framer-6IHNN.framer-4fugl0 > :first-child { margin-top: 0px; } .framer-6IHNN.framer-4fugl0 > :last-child { margin-bottom: 0px; } }", ".framer-6IHNN.framer-v-1s8erf0.framer-4fugl0 { display: block; padding: unset; }", ".framer-6IHNN.framer-v-1s8erf0 .framer-1svay8v, .framer-6IHNN.framer-v-1s8erf0 .framer-mkx79k { left: calc(48.88888888888891% - 21px / 2); position: absolute; top: calc(46.66666666666669% - 2px / 2); z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6IHNN.framer-v-1s8erf0.framer-4fugl0 { gap: 0px; } .framer-6IHNN.framer-v-1s8erf0.framer-4fugl0 > *, .framer-6IHNN.framer-v-1s8erf0.framer-4fugl0 > :first-child, .framer-6IHNN.framer-v-1s8erf0.framer-4fugl0 > :last-child { margin: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 45
 * @framerIntrinsicWidth 45
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"y3kdTaXk5":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Sqyl6Ei8Z":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Frameri88QI5m8e: React.ComponentType<Props> = withCSS(Component, css, "framer-6IHNN") as typeof Component;
export default Frameri88QI5m8e;

Frameri88QI5m8e.displayName = "Menu Icon";

Frameri88QI5m8e.defaultProps = {height: 45, width: 45};

addPropertyControls(Frameri88QI5m8e, {variant: {options: ["l7UUMRneJ", "y3kdTaXk5"], optionTitles: ["menu", "close"], title: "Variant", type: ControlType.Enum}, Sqyl6Ei8Z: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(Frameri88QI5m8e, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})